import ImageCarousel from "../components/ImageCarousel";

function HomePage() {
  return (
    <div>
      <ImageCarousel />
    </div>
  );
}
export default HomePage;
